import { useQuery } from '@tanstack/react-query';
import { createQueryDefinition } from '../queryBuilder';
import { get as getFn } from './api';

export const GetEventQuery = createQueryDefinition(
  (eventId: string) => [eventId, 'event'] as const,
  ({ queryKey: [eventId] }) => getFn(eventId)
);

export const useEventQuery = (eventId: string) =>
  useQuery({
    queryKey: GetEventQuery.getQueryKey(eventId),
    queryFn: GetEventQuery.queryFn,
    enabled: !!eventId,
  });

/*
  A wrapper for the event query that will error if data is not present. This
  allows us to ignore the loading state as long as the base query has been
  mounted somewhere higher and had its loading state checked there.
*/
export const useAssertedEventQuery = (eventId: string) => {
  const result = useEventQuery(eventId);

  if (!result.data) {
    throw new Error(
      'Asserted event query has no data. Make sure the base event query is mounted higher in the tree and its loading state has been checked.'
    );
  }

  return result;
};
