import { createQueryDefinition, createQueryHook } from '../queryBuilder';
import { keys as keysFn, get as getFn } from './api';

export const GetConfigKeysQuery = createQueryDefinition(
  () => ['config', 'keys'] as const,
  () => keysFn()
);

export const useConfigKeysQuery = createQueryHook(GetConfigKeysQuery, {
  select: ({ data }) => data,
});

export const queryGetConfig = createQueryDefinition(
  (eventId: string | number) => ['config', String(eventId)] as const,
  ({ queryKey: [, eventId] }) => getFn(eventId)
);

export const useConfigQuery = createQueryHook(queryGetConfig, {
  // discard items we don't need to store
  select: ({ data }) =>
    data.filter(
      item =>
        !(item.ownerType !== 'event' && item.key === 'is_document_upload_enabled') &&
        !(item.ownerType === 'agenda' && item.key === 'time_format')
    ),
});
